import moment from 'moment-timezone';

export const COUNTRY = {
  KOREA: 'KOREA',
  USA: 'USA'
};

export const ENV = {
  SANDBOX: 'SANDBOX',
  PRODUCTION: 'PRODUCTION'
};

export const ROLE = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN'
};

export const TICKCOLOR_UP = '#f44336';
export const TICKCOLOR_DOWN = '#66bb6a';
export const TICKCOLOR_NEUTRAL = '#ffffff';

export const defaultPreferences = {
  profitRatio: '0',
  lossRatio: '0',
  takeProfit: '0',
  stopLoss: '0',
  buyPeriod: 'once',
  sellPeriod: 'once',
  buyPrice: 'market',
  sellPrice: 'market',
  buyQuantity: 'default',
  sellQuantity: 'default',
  autoTrade: 'off',
  premiumLogic: 'socrates',
  myFund: 'PRIMARY',
  tradingPriority: 'profitLoss',
  assetCount: '10',
  tradeBudgetRatio: '50',
  tradingCountry: 'KOREA',
  emptyOutPortfolio: 'no',
  tradingMode: ENV.SANDBOX
};

export const defaultFunds = [
  {
    name: 'PRIMARY',
    assets: []
  },
  {
    name: 'SECONDARY',
    assets: []
  },
  {
    name: 'INDEX',
    assets: []
  },
  {
    name: 'WATCH LIST',
    assets: []
  }
];

export const tooltipStyle = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0,0,0)',
    color: 'white',
    fontSize: '14px'
  },
  '& .MuiTooltip-arrow': {
    color: 'rgba(0,0,0)'
  }
};

export const isEmptyObject = objectName => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    (objectName.constructor === Object || objectName.constructor === Array)
  );
};

export const isDomestic = country => {
  return country === COUNTRY.KOREA;
};

export const isSandbox = process.env.NODE_ENV === 'development' ? true : false;

export const capitalizeWord = word => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getCurrentDate = () => {
  const today = new Date();
  const dateUTC = moment.tz(today, 'Europe/London');
  const dateKR = moment.tz(today, 'Asia/Seoul');

  return { dateUTC, dateKR };
};

export const isMarketOpen = (country = COUNTRY.KOREA) => {
  if (country === COUNTRY.KOREA) {
    const today = moment.tz(new Date(), 'Asia/Seoul');
    const hour = today.hours();
    const minute = today.minutes();
    const day = today.day();
    const marketOffDatesKR = [
      '2024-01-01',
      '2024-02-09',
      '2024-02-12',
      '2024-03-01',
      '2024-04-10',
      '2024-05-01',
      '2024-05-06',
      '2024-05-15',
      '2024-06-06',
      '2024-08-15',
      '2024-09-16',
      '2024-09-17',
      '2024-09-18',
      '2024-10-03',
      '2024-10-09',
      '2024-12-25',
      '2024-12-31'
    ];

    return ((hour === 8 && minute >= 30) || (hour > 8 && hour < 16)) &&
      !marketOffDatesKR.includes(today.format('YYYY-MM-DD')) &&
      day > 0 &&
      day < 6
      ? true
      : false;
  } else {
    const today = moment.tz(new Date(), 'America/New_York');
    const hour = today.hours();
    const minute = today.minutes();
    const day = today.day();
    const marketOffDatesUSA = [
      '2024-01-01',
      '2024-01-15',
      '2024-02-19',
      '2024-03-29',
      '2024-05-27',
      '2024-06-19',
      '2024-07-04',
      '2024-09-02',
      '2024-11-28',
      '2024-12-25',
      '2025-01-01',
      '2025-01-20',
      '2025-02-17',
      '2025-04-18',
      '2025-05-26',
      '2025-06-19',
      '2025-07-03',
      '2025-07-04',
      '2025-09-01',
      '2025-11-27',
      '2025-11-28',
      '2025-12-24',
      '2025-12-25'
    ];

    return ((hour === 9 && minute >= 30) || (hour > 9 && hour < 16)) &&
      !marketOffDatesUSA.includes(today.format('YYYY-MM-DD')) &&
      day > 0 &&
      day < 6
      ? true
      : false;
  }
};

export const sleep = (ms = 70) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

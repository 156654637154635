import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPortfolio, resetPortfolio } from '../../features/portfolio/portfolioSlice';
import { isMarketOpen, isDomestic, sleep, capitalizeWord } from '../../lib/utils';
import { tooltipStyle, TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL } from '../../lib/constants';
import AssetPreference from './AssetPreference';
import { toast } from 'react-toastify';
import BuyAsset from '../dashboard/BuyAsset';
import SellAsset from '../dashboard/SellAsset';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DataGrid } from '@mui/x-data-grid';

const PortfolioList = ({ country }) => {
  const dispatch = useDispatch();

  const { token, credentials, preferences } = useSelector(state => state.auth);
  const { portfolio, portfolioUS, accountBalance, nass_amt } = useSelector(state => state.portfolio);
  const [isLoading, setIsLoading] = useState(false);

  const { appkey, appsecret, accountNumber, accountCode } = credentials;
  const { tradingMode: env, premiumLogic, assetCount, tradeBudgetRatio, tradingPriority } = preferences;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isPC = useMediaQuery('(max-width: 1200px)');
  const [openBuyDialog, setOpenBuyDialog] = useState(false);
  const [openSellDialog, setOpenSellDialog] = useState(false);

  const handleClickOpenBuy = () => setOpenBuyDialog(true);
  const handleClickOpenSell = () => setOpenSellDialog(true);

  const handleClose = () => {
    setOpenBuyDialog(false);
    setOpenSellDialog(false);
  };

  const getCurrentPrice = async (country, ticker, marketCode) => {
    const API_URL = `/api/${env.toLowerCase()}/assets/price/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        },
        params: {
          userId: token._id,
          appkey,
          appsecret,
          country,
          marketCode
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return isDomestic(country) ? response.data.output.stck_prpr : response.data.output.last;
    } catch (error) {
      console.log(error.response.data);
      return `Error::${error.response.data}`;
    }
  };

  const onSellAllAssets = async () => {
    const API_URL = `/api/${env.toLowerCase()}/trades/order/sell/market`;
    const API_URL_USA = `/api/${env.toLowerCase()}/trades/order/sell/limit`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`
      }
    };

    const result = [];

    if (isDomestic(country)) {
      for (const asset of portfolio) {
        // set request body data
        const ticker = asset.pdno;
        const assetName = asset.prdt_name;
        const quantity = asset.hldg_qty;

        const data = {
          userId: token._id,
          tradeBy: 'user',
          appkey,
          appsecret,
          accountNumber,
          accountCode,
          ticker,
          assetName,
          quantity,
          country
        };

        try {
          const response = await axios.post(API_URL, data, config);

          if (response.data.rt_cd === '0') {
            console.log(`Sell order placed :: asset=${assetName}, ticker=${ticker}, quantity=${quantity}`);

            const res = {
              success: true,
              asset: assetName,
              message: `Sell order placed :: asset=${assetName}, ticker=${ticker}, quantity=${quantity}`
            };

            result.push(res);
          } else if (
            response.data.rt_cd === '1' &&
            (response.data.msg_cd === '40570000' || response.data.msg_cd === '40580000')
          ) {
            console.log(response.data.msg1);

            const res = {
              success: false,
              asset: assetName,
              message: 'Market closed'
            };

            result.push(res);
          } else {
            console.log(response.data);

            const res = {
              success: false,
              asset: assetName,
              message: response.data.message
            };

            result.push(res);
          }
        } catch (error) {
          console.log(error.message);

          const res = {
            success: false,
            message: error.message
          };

          result.push(res);
        }

        await sleep(env === 'SANDBOX' ? 600 : 70);
      }
    } else {
      for (const asset of portfolioUS) {
        // set request body data
        const ticker = asset.ovrs_pdno;
        const marketCode = asset.ovrs_excg_cd;
        const assetName = asset.ovrs_item_name;
        const quantity = asset.ovrs_cblc_qty;

        const data = {
          userId: token._id,
          tradeBy: 'user',
          appkey,
          appsecret,
          accountNumber,
          accountCode,
          ticker,
          assetName,
          quantity,
          price: await getCurrentPrice(country, ticker, marketCode),
          marketCode,
          country
        };

        try {
          const response = await axios.post(API_URL_USA, data, config);

          if (response.data.rt_cd === '0') {
            console.log(`Sell order placed :: asset=${assetName}, ticker=${ticker}, quantity=${quantity} `);

            const res = {
              success: true,
              asset: ticker,
              message: `Sell order placed :: asset=${assetName}, ticker=${ticker}, quantity=${quantity}`
            };

            result.push(res);
          } else if (
            response.data.rt_cd === '1' &&
            (response.data.msg_cd === '40570000' || response.data.msg_cd === '40580000')
          ) {
            console.log(response.data.msg1);

            const res = {
              success: false,
              asset: ticker,
              message: 'Market closed'
            };

            result.push(res);
          } else {
            console.log(response.data);

            const res = {
              success: false,
              asset: ticker,
              message: response.data.message
            };

            result.push(res);
          }
        } catch (error) {
          console.log(error.response);
          console.log(error.message);

          const res = {
            success: false,
            message: error.message
          };

          result.push(res);
        }

        await sleep(env === 'SANDBOX' ? 600 : 70);
      }
    }

    result.every(order => order.success === true)
      ? toast.success(`[${country}] Sell orders placed successfully!`)
      : result.every(order => order.message === 'Market closed')
      ? toast.error(
          <>
            <h4>[${country}] Market Closed!</h4>
            <p>Please try again when the market is open.</p>
          </>
        )
      : toast.error(`[${country}] Failed to place sell orders`);
  };

  const getPremiumBuyList = async (country, assetCount) => {
    try {
      const API_URL = '/api/lists/';
      const config = {
        params: {
          premiumLogic: isDomestic(country) ? premiumLogic : premiumLogic + 'US',
          trendType: 'UP',
          country
        }
      };

      const response = await axios.get(API_URL + 'premiumlogic', config);

      return response.data.slice(0, assetCount);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const buyPremiumAsset = async (userId, credentials, asset, budget, country) => {
    const { appkey, appsecret, accountNumber, accountCode } = credentials;
    const ticker = asset.shcode;
    const assetName = asset.hname;
    const marketCode = isDomestic(country) ? '' : asset.marketcode;

    try {
      // get the asset price
      const price = await getCurrentPrice(country, ticker, marketCode);

      // calculate the quantity of the asset to buy
      const quantity = isDomestic(country) ? Math.floor(budget / price) : Math.floor(budget / (price * 1310));

      if (quantity <= 0) {
        throw new Error(`Not enough budget to buy ${assetName} :: quantity=${quantity}, price=${price}`);
      }

      const API_URL = isDomestic(country)
        ? `/api/${env.toLowerCase()}/trades/order/buy/market`
        : `/api/${env.toLowerCase()}/trades/order/buy/limit`;
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`
        }
      };
      const data = isDomestic(country)
        ? {
            userId,
            tradeBy: 'user',
            appkey,
            appsecret,
            accountNumber,
            accountCode,
            ticker,
            assetName,
            quantity: quantity.toString(),
            country
          }
        : {
            userId,
            tradeBy: 'user',
            appkey,
            appsecret,
            accountNumber,
            accountCode,
            ticker,
            assetName,
            quantity: quantity.toString(),
            price,
            marketCode,
            country
          };

      const response = await axios.post(API_URL, data, config);

      if (response.data.rt_cd === '0') {
        console.log(
          `Buy order completed! :: asset=${assetName}, ticker=${ticker}, price=${price}, quantity=${quantity} `
        );

        return response.data;
      } else {
        console.log(
          `Buy order failed (${response.data.msg1}) :: asset=${assetName}, ticker=${ticker}, price=${price}, quantity=${quantity}`
        );
      }
    } catch (error) {
      console.log(`Buy order failed :: asset=${assetName}, ticker=${ticker}`);
      throw error;
    }
  };

  const onBuyPremiumAssets = async () => {
    try {
      // get the Egleo assets to buy
      const assets = await getPremiumBuyList(country, assetCount);

      if (assets.length === 0) {
        console.log(`No ${capitalizeWord(premiumLogic)} assets available today`);
        toast.error(`No ${capitalizeWord(premiumLogic)} assets available today`);
        return;
      }

      const budget =
        accountBalance > 0 ? Math.floor(((Number(tradeBudgetRatio) / 100) * accountBalance) / Number(assetCount)) : 0;

      console.log(`Account Balance: ${accountBalance}, Budget: ${budget}\n`);

      if (budget === 0) {
        console.log(`Not enough balance to buy ${capitalizeWord(premiumLogic)} asset`);
        return;
      }

      for (const asset of assets) {
        try {
          await buyPremiumAsset(token._id, credentials, asset, budget, country);
        } catch (error) {
          console.log(error);
        }

        await sleep(env === 'SANDBOX' ? 600 : 70);
      }

      toast.success('Buy orders placed successfully!');
    } catch (error) {
      console.log(error);
      toast.error('Failed to place buy orders.');
    }
  };

  const CustomNoRowsOverlay = () => (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      No assets.
    </Box>
  );

  const renderAssetName = params => {
    return isDomestic(country) ? (
      <Link
        color='primary.light'
        underline='none'
        href={`https://finance.naver.com/item/main.naver?code=${params.row.assetInfo.ticker}`}
        target='_blank'
        rel='noreferrer'
      >
        {params.value}
      </Link>
    ) : (
      <Tooltip disableFocusListener arrow title={params.row.assetInfo.assetName} PopperProps={{ sx: tooltipStyle }}>
        <Link
          color='primary.light'
          underline='none'
          href={`https://finance.yahoo.com/quote/${params.value}?p=${params.value}`}
          target='_blank'
          rel='noreferrer'
        >
          {params.value}
        </Link>
      </Tooltip>
    );
  };

  const renderProfit = params => {
    return (
      <Stack
        flexDirection='row'
        alignItems='center'
        color={params.value > 0 ? TICKCOLOR_UP : params.value < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL}
      >
        {params.value > 0 ? (
          <ArrowDropUpIcon />
        ) : params.value < 0 ? (
          <ArrowDropDownIcon />
        ) : (
          <ArrowDropDownIcon sx={{ visibility: 'hidden' }} />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <span>
            {isDomestic(country)
              ? Math.abs(params.value).toLocaleString('en-us')
              : `$${Math.abs(params.value).toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`}
          </span>
          <span>{Math.abs(params.row.assetProfitRatio).toFixed(1).toLocaleString('en-us')}%</span>
        </div>
      </Stack>
    );
  };

  const renderTradeButton = params => {
    const assetData = {
      shcode: params.row.assetInfo.ticker,
      hname: params.row.assetInfo.assetName,
      quantity: params.row.assetInfo.quantity || '',
      marketcode: params.row.assetInfo.marketCode || ''
    };

    return (
      <Stack direction='row' spacing={1}>
        <BuyAsset asset={assetData} country={country} />
        <SellAsset asset={assetData} country={country} />
      </Stack>
    );
  };

  const renderSettingsIcon = params => {
    return (
      <>
        <AssetPreference
          assetPreferences={params.row.assetPreferences}
          ticker={params.row.assetInfo.ticker}
          asset={params.row.assetInfo}
        />
      </>
    );
  };
  const columns = [
    {
      field: 'assetName',
      headerName: 'Asset',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: isMobile ? 0.6 : 0.8,
      hideSortIcons: true,
      renderCell: renderAssetName
    },
    {
      field: 'assetPrice',
      headerName: 'Price',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.4,
      sortable: false
    },
    {
      field: 'assetQuantity',
      headerName: 'Quantity',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.4,
      sortable: false
    },
    {
      field: 'assetAvgPrice',
      headerName: 'Avg. Price',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: { xs: 0.4, sm: 0.5, md: 0.4 },
      sortable: false
    },
    {
      field: 'assetProfit',
      headerName: 'Profit',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: { xs: 0.4, sm: 0.5 },
      sortable: false,
      renderCell: renderProfit
    },
    {
      field: 'tradeBtn',
      headerName: 'Trade',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.6,
      sortable: false,
      renderCell: renderTradeButton
    },
    {
      field: 'setting',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 0.2,
      sortable: false,
      renderCell: renderSettingsIcon
    }
  ];
  const rows = [];

  let totalProfitLoss = 0;
  let totalAssetValue = 0;

  useEffect(() => {
    setIsLoading(true);

    const promise = dispatch(getPortfolio(country));

    setIsLoading(false);

    return () => {
      promise.abort('getPortfolio aborted!');
      dispatch(resetPortfolio());
    };
  }, [country, dispatch]);

  isDomestic(country) &&
    portfolio.forEach((asset, idx) => {
      const ticker = asset.pdno;
      const assetName = asset.prdt_name;
      const prefData = preferences.assets || [];
      const found = Object.entries(prefData).find(entry => entry[0] === ticker);
      let assetPreferences = [];

      totalProfitLoss += Number(asset.evlu_pfls_amt);
      totalAssetValue += Number(asset.evlu_amt);

      const assetInfo = {
        assetName,
        ticker,
        quantity: asset.hldg_qty,
        profit: asset.evlu_pfls_amt,
        profitRatio: asset.evlu_pfls_rt
      };

      if (found) {
        const data = Object.entries(found[1]).map(entry => {
          if (entry[1] === true) return entry[0];
          else return null;
        });

        assetPreferences = data.filter(el => el !== null);
      }

      const data = {
        id: idx + 1,
        assetName: assetName,
        assetPrice: Number(Math.round(asset.prpr)).toLocaleString('en-us'),
        assetQuantity: Number(asset.hldg_qty).toLocaleString('en-us'),
        assetAvgPrice: Number(Math.round(asset.pchs_avg_pric)).toLocaleString('en-us'),
        assetProfit: Number(asset.evlu_pfls_amt),
        assetProfitRatio: Number(asset.evlu_pfls_rt),
        asset: asset,
        assetInfo: assetInfo,
        assetPreferences: assetPreferences
      };

      rows.push(data);
    });

  !isDomestic(country) &&
    portfolioUS.forEach((asset, idx) => {
      const ticker = asset.ovrs_pdno;
      const assetName = asset.ovrs_item_name;
      const marketCode = asset.ovrs_excg_cd;
      const prefData = preferences.assets || [];
      const found = Object.entries(prefData).find(entry => entry[0] === ticker);
      let assetPreferences = [];

      totalProfitLoss += Number(asset.frcr_evlu_pfls_amt);
      totalAssetValue += Number(asset.ovrs_stck_evlu_amt);

      const assetInfo = {
        assetName,
        ticker,
        quantity: asset.ovrs_cblc_qty,
        marketCode
      };

      if (found) {
        const data = Object.entries(found[1]).map(entry => {
          if (entry[1] === true) return entry[0];
          else return null;
        });

        assetPreferences = data.filter(el => el !== null);
      }

      const data = {
        id: idx + 1,
        assetName: ticker,
        assetPrice: `$${Number(asset.now_pric2).toFixed(2).toLocaleString('en-us')}`,
        assetQuantity: Number(asset.ovrs_cblc_qty).toLocaleString('en-us'),
        assetAvgPrice: `$${Number(asset.pchs_avg_pric).toFixed(2).toLocaleString('en-us')}`,
        assetProfit: Number(asset.frcr_evlu_pfls_amt),
        assetProfitRatio: Number(asset.evlu_pfls_rt),
        assetInfo,
        assetPreferences
      };

      rows.push(data);
    });

  return (
    <>
      <Box width={'100%'}>
        <DataGrid
          dense
          autoHeight
          rows={rows}
          columns={columns}
          loading={isLoading}
          sortingOrder={['asc', 'desc']}
          disableRowSelectionOnClick
          disableColumnMenu
          columnVisibilityModel={{
            assetName: true,
            assetPrice: !isMobile ? true : false,
            assetQuantity: !isMobile ? true : false,
            assetAvgPrice: !isPC ? true : false,
            assetProfit: true,
            tradeBtn: true,
            setting: !isMobile ? true : false
          }}
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true
            }
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10]}
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
            '& .MuiDataGrid-columnHeader': { backgroundColor: '#001021' }
          }}
        />
      </Box>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 2, sm: 4 }}
        mt={4}
        display='flex'
        justifyContent='center'
      >
        {((isDomestic(country) && portfolio.length !== 0) || (!isDomestic(country) && portfolioUS.length !== 0)) && (
          <Button
            variant='contained'
            disabled={
              isDomestic(country) && !isMarketOpen(country)
                ? true
                : !isDomestic(country) && !isMarketOpen(country)
                ? true
                : false
            }
            sx={{ width: { sm: '250px' } }}
            fullWidth={isMobile ? true : false}
            onClick={handleClickOpenSell}
          >
            Sell {isDomestic(country) ? 'Korea' : 'USA'} Assets
          </Button>
        )}

        {openSellDialog && (
          <Dialog
            maxWidth={'xs'}
            PaperProps={{ sx: { width: '400px' } }}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)'
                }
              }
            }}
            open={openSellDialog}
            onClose={handleClose}
            closeAfterTransition={false}
          >
            <DialogTitle textAlign={'center'}>Liquidate My Portfolio</DialogTitle>
            <DialogContent
              dividers
              sx={{
                padding: '1rem auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              spacing={2}
            >
              <Typography variant='body2' component='p' m={1}>
                You are about to sell all {isDomestic(country) ? 'KOREA' : 'USA'} assets in your portfolio.
              </Typography>
              <Typography variant='body2' component='p' m={1}>
                This order overrides any trading preferences and assets will be sold at the market price.
              </Typography>
              <Typography variant='h6' color={'primary'} fontWeight={500} m={2}>
                Sure to proceed?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  onSellAllAssets();
                  handleClose();
                }}
              >
                Proceed
                {/* Sell {isDomestic(country) ? 'Korea' : 'USA'} Assets */}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {tradingPriority === premiumLogic && isMarketOpen(country) && (
          <Button
            variant='contained'
            disabled={
              isDomestic(country) && !isMarketOpen(country)
                ? true
                : !isDomestic(country) && !isMarketOpen(country)
                ? true
                : false
            }
            sx={{ width: { sm: '250px' } }}
            fullWidth={isMobile ? true : false}
            onClick={handleClickOpenBuy}
          >
            Buy {premiumLogic} {isDomestic(country) ? 'Korea' : 'USA'} Assets
          </Button>
        )}

        {openBuyDialog && (
          <Dialog
            maxWidth={'xs'}
            PaperProps={{ sx: { width: '400px' } }}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)'
                }
              }
            }}
            open={openBuyDialog}
            onClose={handleClose}
            closeAfterTransition={false}
          >
            <DialogTitle textAlign={'center'}>Buy {capitalizeWord(premiumLogic)} Assets</DialogTitle>
            <DialogContent
              dividers
              sx={{
                padding: '1rem auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              spacing={2}
            >
              <Typography variant='body2' component='p' m={1}>
                You are about to buy {capitalizeWord(premiumLogic)} assets.
              </Typography>
              <Typography variant='body2' component='p' m={1}>
                This order will calculate the number of shares of each {capitalizeWord(premiumLogic)} assets and place
                buy orders based on your "System Trading" settings in the Preferences.
              </Typography>
              <Typography variant='h6' color={'primary'} fontWeight={500} m={2}>
                Sure to proceed?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  onBuyPremiumAssets();
                  handleClose();
                }}
              >
                Proceed
                {/* Buy {premiumLogic} Assets */}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Stack>

      <Stack
        direction='row'
        display={{ xs: 'grid', sm: 'flex' }}
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
        alignItems={'center'}
        justifyContent='space-around'
        gap={2}
        mt={5}
        p={2}
        pl={2}
        sx={{ border: '0.5px solid #515151', borderRadius: '6px' }}
      >
        <Typography variant='body2'>Cash: {Number(accountBalance).toLocaleString('en-us')}</Typography>
        <Typography variant='body2'>Net Balance: {Number(nass_amt).toLocaleString('en-us')}</Typography>
        <Typography variant='body2'>
          Asset Total:{' '}
          {isDomestic(country)
            ? Number(totalAssetValue).toLocaleString('en-us')
            : `$${Number(totalAssetValue).toLocaleString('en-us', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`}
        </Typography>
        <Stack direction='row' alignItems={'center'}>
          <Typography variant='body2' component={'span'}>
            Profit/Loss:
          </Typography>
          <Typography
            variant='body2'
            component={'span'}
            display='flex'
            alignItems='center'
            color={totalProfitLoss > 0 ? TICKCOLOR_UP : totalProfitLoss < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL}
            pl={0.5}
          >
            {totalProfitLoss > 0 ? <ArrowDropUpIcon /> : totalProfitLoss < 0 ? <ArrowDropDownIcon /> : ''}
            {isDomestic(country)
              ? Math.abs(totalProfitLoss).toLocaleString('en-us')
              : `$${Math.abs(totalProfitLoss).toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default PortfolioList;
